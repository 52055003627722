import React from 'react'
import { graphql, PageProps } from 'gatsby'
//Types
import { PageContextProps, PageInfoProps, PublicationProps } from '../types/pages'
import { SectionBase } from '../types/sections'
// Components
import { Layout, Seo } from '../components/commons'
import Section from '../components/sections'
import { sectionProductValues } from '../components/sections/ProductValues/ProductValues.fragment'

interface HomeQuery {
  datoCmsHome: {
    pageInfo: PageInfoProps
    meta: PublicationProps
    sections: SectionBase[]
  }
}

const IndexPage: React.FC<PageProps<HomeQuery, PageContextProps>> = ({ data, pageContext }) => {
  const {
    datoCmsHome: { pageInfo, meta, sections },
  } = data

  return (
    <Layout
      pageId={pageContext.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={pageInfo.hidePrefooter}
    >
      <Seo
        slug={''}
        canonicalUrl={pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={meta}
        content={pageInfo.seo}
        preventIndexing={pageInfo.preventIndexing}
      />
      {sections.map((s: SectionBase) => s?.model && <Section key={s.id} {...s} />)}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery($locale: String!) {
    datoCmsHome(locale: $locale) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      sections {
        ...sectionHomeHeroFragment
        ...sectionLogoListFragment
        ...sectionFeatureSimpleFragment
        ...sectionMarqueeFragment
        ...sectionTabulatedContentFragment
        ...sectionDestinationFragment
        ...sectionBenefitsFragment
        ...sectionContentFragment
        ...sectionTwoImagesFragment
        ...sectionDestinationListFragment
        ...sectionFAQFragment
        ...sectionFeaturedArticlesFragment
        ...sectionFeaturedIntegrationsFragment
        ...sectionFeaturedTutorialsFragment
        ...sectionFeaturedUseCasesFragment
        ...sectionFeaturedCustomerStoriesFragment
        ...sectionFeaturedExternalResourcesFragment
        ...sectionPartnersFragment
        ...sectionImagesMarqueeFragment
        ...sectionKPIFragment
        ...sectionPushFragment
        ...sectionTestimonialFragment
        ...sectionTestimonialSlideshowFragment
        ...sectionComparisonFragment
        ...sectionProductValues
      }
    }
  }
`
